
.tootip{
  position: relative;
}

.tootiptext{
  width: 100%;
  display: none;
  position: absolute;
  top: 100%;
  left: 0px;
}
.tootip:hover .tootiptext{
  display: block;
}
.overlay-nav{
  top: calc(100% - 40px);
  /* background-color:rgba(0, 0, 0, 0.8); */
  background-color:rgba(0,0,0,0.5);
}

.breamItem::after{
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  top: 40%;
  left: 110%;
  transform: rotate(45deg);
  border: 1px solid ;
  border-color: #1A73E8 #1A73E8 transparent transparent;
}

.checkbox-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: rgba(26, 115, 232, 0.1);
}
.checkbox-item > span:first-child {
  font-size: 16px;
  font-weight: 600;
  opacity: 0;
  color:  #1A73E8;
}

input[type='checkbox']:checked ~ .checkbox-item > span {
  opacity: 1 !important;
}
