
.btn {
    padding: 4px 12px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
}
.table-manager > thead > tr > td {
  padding: 30px 22px;
}

.table-manager > tbody > tr > td {
  padding: 40px 25px;
  border-right: 2px solid rgba(26, 115, 232, 0.05);
  text-align: center;
}

.table-manager > thead > tr:nth-child(even),
.table-manager > tbody > tr:nth-child(odd) {
    background-color: rgba(99, 99, 99, 0.1);
}

.table-manager > thead > tr > td {
  font-size: 20px;
}

.btn-primary {
  color: var(--white-color) ;
  background-color: var(--primary) !important;
}

.btn-empty {
    border: 1px solid var(--primary);
}

.activeLink {
  background-color: #EBF3FF;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
}

.modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.radio-label {
  width: 20px;
  height: 20px;
  display: block;
  position: relative;
  border: 2px solid var(--color);
  border-radius: 50%;
}

.radio-label::after {
  position: absolute;
  content: "";

  display: none;
  width: 60%;
  height: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color);
  border-radius: 50%;

}

.radio-input:checked + .radio-label::after {
 display: block;
}

/* .swiper-item-thumb .swiper-wrapper {
  cursor: pointer;
  border: 1px solid var(--white-color);
} */

.swiper-item-thumb  {

  width: 300px;
}
.scrollbar::-webkit-scrollbar {
  width: 2px;
}
.scrollbar::-webkit-scrollbar-track {
  background: var(--text-secondary); 
}

.swiper-item-thumb  .swiper-slide-thumb-active {
  min-height: 34px;
  max-height: 34px;
  min-width: 34px;
  max-width: 34px;
  border-radius: 6px;
  border: 2px solid var(--white-color);
  overflow: hidden;
} 

.swiper-banner-home .swiper-slide-thumb-active > div {
  background-color: var(--white-color);
}

.nav-link > ul {
  display: none;
}

/* .nav-link > ul::after {
  position: absolute;
  content: "";
  display: block;
  top: -50px;
  left: 0;
  width: 50%;
  height: 50px;
  background-color: transparent;

} */

.nav-link:hover > ul {
  display: block;
    cursor: default;
    transform-origin: 32px top;
    animation: headerNotifyRow ease-in 0.2s;
    will-change: opacity transform;
    z-index: 12;
}

@keyframes headerNotifyRow{
  from{
      transform: scale(0);
      opacity: 0;
  }
  to{
      transform: scale(1);
      opacity: 1;
  }
}

.nav-link:hover > ul {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
}

.nav-link:hover > ul > li:hover {
  background-color: var(--bg-hover);
}
.nav-link_mobile >  ul {
  display: none;
}



.nav-link_mobile:hover > ul {
    display: block;
    cursor: default;
    top: 0;
    left: 120px;
    transform-origin: 32px top;
    animation: headerNotifyRow ease-in 0.2s;
    will-change: opacity transform;
    z-index: 9999;
} 

.nav-link_mobile:hover > ul > li:hover {
  background-color: var(--bg-hover);
}

.item-hover:hover {
  background-color: var(--bg-hover);
  transition: all linear 0.3s;
}

.popup-show {
  animation: show-popup 0.5s ease;

} 


.popup-hide {
  animation: hide-popup 0.3s ease forwards;
}


@keyframes show-popup {
  from {
      transform: translateY(-55px);
      opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes hide-popup {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  99% {
    transform: translateY(-55px);
    opacity: 0;
  }

  100% {
    display: none;
  }

 
}

.hoverChildren {
  transform: translateY(100%);
  opacity: 0;
  background: rgba(255, 255, 255, 0.8);
}

.parentHover:hover > .hoverChildren {
  transform: translateY(0);
  opacity: 1;
  transition: all ease 0.3s;
  
}


.btn-select {
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 0 12px;
  cursor: default;
}


.select-box-item {
  display: none;
  list-style: none;
  background-color: var(--white-color);
  width: 100%;
  padding: 4px;
  height: 300px;
  overflow-y: scroll;
  
}

.select-box-item > li {
  padding: 4px 12px;
  cursor: pointer;;

}

.select-box-item > li:hover {
  background-color: #f5f5f5;
}

.btn-select:focus ~ .select-box-item {
  display: block;
}

.contact-avatar {
  padding: 3px;
  background-color: var(--white-color);
  border-radius: 100rem;
  position: relative;

}

.contact-avatar::after {
  content: "";
  position: absolute;
  display: block;
  inset: -2px;
  border-radius: 50%;
  border: 3px solid #0DD415;
}

.contact-avatar::before  {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  bottom: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #0DD415;
  border: 2px solid var(--white-color);
  z-index: 2;
}

.contact_parent:hover .contact_children {
    display: block;
}


.contact_children {
  display: none;
  transition: all 0.3s ease;
}


.contact_children::before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  bottom: 0;
  width: 50px;
  right: -50px;
}

.ce-block__content {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0;
}

.img-delete{
  display: none;
}

.imagepreview:hover .img-delete{
  display: block;
}
.search_item:hover{
  box-shadow: 0px 0px 20px -5px rgb(0 0 0 / 20%);
}

.banner_home-after::after {
  position: absolute;
  content: "";
  display: block;
  inset: 0;
  background: linear-gradient(180deg, rgba(20, 78, 140, 0) 50%, #144E8C 100%);
  /* background: rgba(20, 78, 140, 0.7); */
  z-index: 1;
  mix-blend-mode: normal;
}
.login-hover-root:hover .login-hover{
  display: block;
}

.box-shadow {
  /* animation: shodow 0.8s ease-in-out infinite alternate; */
  border: 1px solid var(--color)
}

@keyframes shodow {
  from {
      box-shadow: 0 0 2px var(--color),
    0 0 6px   var(--color);
  }

  to {
    box-shadow: 0 0 10px var(--color),
    0 0 12px var(--color),
    0 0 18px var(--color),
    0 0 22px var(--color);
  }

  
}
.ce-toolbar {
  right: unset;
} 

@media (max-width: 650px) {
  .ce-popover {
      position: fixed;
      max-width: none;
      min-width: auto;
      left: 5px;
      right: 5px;
      bottom: calc(5px + env(safe-area-inset-bottom));
      top: 0;
      border-radius: 10px;
      z-index: 99999;
  }



}

@media (max-width: 767px) {

  .table-manager > tbody > tr > td {
   font-size: 14px;
  }
  

  
  .table-manager > thead > tr > td {
    font-size: 16px;
  }
}

.ce-settings {
  left: 50px;
}

.ce-toolbar__actions {
  flex-direction: column;
}

.ce-popover--opened {
  left: 30px;
}
.ce-paragraph  {
  word-wrap: break-word;
}

.ce-settings {
  left: 50px;
}

.ce-toolbar__actions {
  flex-direction: column;
}

.ce-popover--opened {
  left: 30px;
}

.ce-paragraph  {
  word-wrap: break-word;
}

.ios{
  overflow-y: auto;
  -webkit-overflow-scrolling:touch !important;
  height: 180px;
  border-radius: 10px;
  overflow: hidden;
  /* margin-left: 100px; */
}

.ios iframe{
  height: 100%;
  min-width: 100%;
  width: 100px;
  *width: 100%;
  position: absolute;
  top: 0;
  right:0;
  bottom:0;
  left: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}