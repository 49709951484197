.bg-fillter{
    background-color: var(--bg-fillter);
}
.manager-active::before{
    content: "";
    width:5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--primary-link);
    border-radius: 0 6px 6px 0;
}

