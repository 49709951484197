.break-point-login {
    width: 4px;
    height: 4px;

    border-radius: 100rem;
    background-color: #f5f5f5;
    margin: 0 auto;
    margin-top: 38px;
    position: relative;
}

.break-point-login::after {
    position: absolute;
    content: "";
    display: block;

    height: 2px;
    width:80px;
    top: 50%;
    left: -100px;
    transform: translateY(-50%);
    background-color: #f5f5f5;


}

.break-point-login::before {
    position: absolute;
    content: "";
    display: block;

    height: 2px;
    width: 80px;
    top: 50%;
    right: -100px;
    transform: translateY(-50%);
    background-color: #f5f5f5;


}

