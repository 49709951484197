

.banner::after {
    position: absolute;
    content: "";
    display: block;
    inset: 0;
    background-image: linear-gradient(to bottom, var(--white-color), var(--back-color) 80deg);
}

.slider-company-home {
    height: auto;
    position: relative;
    width: 100%;
}

.slider-company-home .slider-company-home-content {
    padding: 0 120px;
    /* overflow: hidden; */

}


.slider-company-home  .swiper-button-prev {
   position: absolute;
   left: 0;
  }
  
  .slider-company-home .swiper-button-next {
   position: absolute;
   right: 0;
  }

  .slider-company-home-item div  {
    width: 100%;
    height:180px;
    background-color: var(--white-color);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slider-company-home-item:first-child {
    padding-left: 24px;
  }

  .slider-company-home .swiper-slide {

    /* Center slide text vertically */
    height: calc((100% - 30px) / 2) !important;
  }

  .project-active > div {
    display: none;
    transition: 0.3s linear ease;
  }

  .project-active:hover > div{
    display: block;
    animation: animateToTop 0.3s ease;
  } 

  @keyframes animateToTop {
    from {
      transform: translateY(50%);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
/* lssm:h-[60px] md:h-[80px] xl:h-[100px] 2xl:h-[128px] */


  .banner_home_primary,  .banner_home_primary_images {
    height: calc(100vh - 130px);
    max-height: calc(100vh - 130px);
    min-height: calc(100vh - 130px);
  }

  .bannerHome {
    height: max-content;
    max-height: max-content;
    min-height: max-content;
  }

  .bg-product {
    background: linear-gradient(239.06deg, var(--primary) 1.06%, var(--primary) 29.69%, var(--primary) 54.32%, rgba(255, 255, 255, 0) 115.56%);
  }

  .tooltipLable{
    display: none;
    z-index: 999;
    box-shadow: 0px 0px 20px -5px rgb(0 0 0 / 20%);
  }

  .productTooltip:hover .tooltipLable {
    display: block;
  }
  
  .tooltipLable::before{
    content: "";
    height: 20px;
    position: absolute;
    display: block;
    top:6%;
    right: 100%;
    border-top: 31px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 100px solid var(--bg-lightBlue);
  }
   
  @media screen and (max-width: 991px) {
    .tooltipLable::before{
      display: none;
    }
  }
  @media (max-width: 1280px) {
    .bannerHome {
      height: max-content;
      max-height: max-content;
      min-height: max-content;
    }
    .banner_home_primary {
      height: calc(100vh - 102px);
      max-height: calc(100vh - 102px);
      min-height: calc(100vh - 102px);
    }
  }


   @media (max-width: 998px) {
    .banner_home_primary {
      height: 500px;
      max-height: 500px;
      min-height: 500px;
    }
    
    .banner_home_primary_images {
      height: auto;
      max-height: auto;
      min-height: auto;
    }
  }

  @media (max-width: 567px) {
    .banner_home_primary {
      height: 550px;
      max-height: 550px;
      min-height: 550px;
    }

     .banner_home_primary_images {
      height: auto;
      max-height: auto;
      min-height: auto;
    }
  } 