.product-detail > .swiper-wrapper{
    /* height: 540px; */
    height: 400px;
    /* border-radius: 4px; */
}
.product-detail > .swiper-pagination{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    bottom: 0;
    /* margin-top: 50px; */
}
.product-detail > .swiper-pagination > span{
    width: 24.8%;
    height: 40px;
    border-radius: 0;
}
.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet :last-child{
       margin-right: 0 !important;
}
.product-detail > .swiper-pagination > span :not(.product-detail > .swiper-pagination > span:last-child){
    margin-right: 10px !important;
}
.product-detail > .swiper-pagination > span > img{
    display: block;
}
.product-detail > .swiper-pagination > span > img{
    width: 100%;
    height: 100%;
}
.products-related{
    display: flex;
    flex-flow: column;
}
.products-related > .swiper{
    position: inherit;
}
.products-related > .swiper-pagination{
    position: static;
    height: auto;
    margin-top: 41px;
}
/* 
.jodit-container {
    min-height: 500px !important;
} */

@media screen and  (max-width: 1280px) {
    .products-related > .swiper-pagination{
        margin-top: 20px;
    }
   
}

@media screen and  (max-width: 639px) {
    .product-detail > .swiper-wrapper{
        height: 219px;
        border-radius: 4px;
    }
    .product-detail > .swiper-pagination{
        margin-top: 0;
        justify-content: center;
        bottom: 10px;
    }
    .product-detail > .swiper-pagination > span{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        /* background-color: #999999; */
        margin: 0px 10px 0 0 !important;
    }
    .product-detail > .swiper-pagination > span > img{
        display: none;
    }
    .swiper-pagination-bullet-active{
        background-color: var(--primary);
    }

}
@media screen and  (max-width: 320px){
    .product-detail > .swiper-wrapper{
        height: 180px;
        border-radius: 4px;
    }
}



@media screen and (min-width: 640px) {
    .product-detail > .swiper-wrapper{
        height: 300px;
    }
    .product-detail > .swiper-pagination > span{
        height: 65px;
    }
}
@media screen and (min-width: 768px) {
    .product-detail > .swiper-wrapper{
        height: 400px;
    }
}
@media screen and (min-width: 992px) {
    .product-detail > .swiper-pagination{
        justify-content: center;
    }
}
@media screen and (min-width: 1536px) {
    .product-detail > .swiper-wrapper{
        height: 500px;
    }
    .product-detail > .swiper-pagination > span{
        height: 100px;
    }
}
@media screen and (min-width: 1920px) {
    .product-detail > .swiper-wrapper{
        height: 540px;
    }
}

