@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@fontsource/bai-jamjuree";
@import "animate.css";

/* @font-face {
    font-family: "Bai Jamjuree";
    src: url(fonts/SanFranciscoText-Light.otf);
  } */



:root {
    --primary-link: #0058DB;
    --primary: #144E8C;
    --text-primary: #144E8C;
    --text-secondary: #636363;
    --text-gray: #B6A4A4;
    --white-color: #fff;
    --black-color: #000;
    --text-green: #05A84B;
    --bg-fillter: rgba(0, 0, 0, 0.5);
    --bg-hover: #f0f0f6;
    --bg-lightBlue: #E8EEF4;
}

@import "./common.css";
@import "./home.css";
@import "./cart.css";
@import "./about.css";
@import "./login.css";
@import "./swiper.css";
@import "./dashboard.css";
@import "./loadding.css";


body {
    font-family: "Bai Jamjuree";

   overflow-x: hidden;
}



pre {
    font-family: "Bai Jamjuree";
}