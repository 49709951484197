

.history_animate {
    transform: translateY(50px);
    opacity: 0;
}

.history_animate-active {
    animation: animated ease 0.8s;
} 

.history_animate-active-mb {
    animation: animated ease 0.8s ;
}

@keyframes animated {
    from {
        transform: translateY(200px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}



    .core-event::after{
        content: "";
        position: absolute;
        display: block;
        width: 70%;
        height: 120px;
        border-width: 1px;
        border-style: solid;
        border-color: var(--primary) var(--primary) transparent transparent;
        top: 50%;
        /* transform: translateY(-50%); */
        right: -70%;
        z-index: 3;
    }
    
    .core-old::after {
        content: "";
        position: absolute;
        display: block;
        width: 70%;
        height: 95px;
        border-width: 1px;
        border-style: solid;
        border-color: var(--primary) transparent  transparent var(--primary);
        top: 70%;
        left: -70%;
        z-index: 3;
    }


    .brochure {
        top: 7%;
        left: 50%;
        transform: translate(-75%, 0);
    }


@media (max-width: 1919px) {
    .core-event::after{
        content: "";
        position: absolute;
        display: block;
        width: 70%;
        height: 130px;
        border-width: 1px;
        border-style: solid;
        border-color: var(--primary) var(--primary) transparent transparent;
        top: 50%;
        /* transform: translateY(-50%); */
        right: -70%;
        z-index: 3;
    }
    
    .core-old::after {
        content: "";
        position: absolute;
        display: block;
        width: 70%;
        height: 90px;
        border-width: 1px;
        border-style: solid;
        border-color: var(--primary) transparent  transparent var(--primary);
        top: 70%;
        left: -70%;
        z-index: 3;
    }
    
}

@media (max-width: 1490px) {
    .core-event::after{
        content: "";
        position: absolute;
        display: block;
        width: 30%;
        height: 130px;
        border-width: 1px;
        border-style: solid;
        border-color: var(--primary) var(--primary) transparent transparent;
        top: 50%;
        /* transform: translateY(-50%); */
        right: -30%;
        z-index: 3;
    }
    
    .core-old::after {
        content: "";
        position: absolute;
        display: block;
        width: 30%;
        height: 90px;
        border-width: 1px;
        border-style: solid;
        border-color: var(--primary) transparent  transparent var(--primary);
        top: 70%;
        left: -30%;
        z-index: 3;
    }
    
}

@media (max-width: 1200px) { 
    .core-event::after{
       display: none;
    }
    
    .core-old::after {
        display: none;
    }

}
